#portfolioSection {
    width: 100vw;
    color: #00002f;
    margin: -50px 0px;
}

#portfolioSection h2 {
    font-size: 4.5vw;
    width: 100vw;
    display: block;
    margin-top: 0;
    text-align: center;
}

.portfolioItem {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 80vw;
    margin: 0 auto 100px auto;
}

#portfolioSection h3 {
    font-weight: 500;
    font-size: 50px;
    width: 80vw;
    margin: 0 auto;
}

#portfolioSection h4 {
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px;
    /* text-decoration: underline; */
}

#portfolioSection li {
    font-size: 20px;
    /* font-family: "Consolas"; */
}

.portfolioImgs {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 50px; */
}

.layoutImage {
    width: 40vw;
    padding: 50px;
    margin: auto auto;
}

.websiteButton {
    background-color: #94ebb8;
    height: 150px;
    width: 150px;
    border-radius: 75px;
    position: absolute;
    right: 150px;
    text-decoration: none;
    color: #00002f;
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: all 0.25s;
}

.websiteButton:hover {
    background-color: #00002f;
    color: #94ebb8;
    transition: all 0.25s;
    animation-name: skillBounce;
    animation-timing-function: ease-in-out;
    animation-duration: 0.75s;
}

.websiteButton p {
    font-family: "Consolas";
    width: 75%;
    margin: auto 0;
}

@keyframes skillBounce {
    0% { transform: scale(100%) }
    25% { transform: scale(105%) }
    50% { transform: scale(95%) }
    75% { transform: scale(100%) }
}