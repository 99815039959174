@media screen and (max-width: 1200px) {
    #header-textbox {
        left: 10%;
        width: 80%;
        font-size: 6.5vw;
    }
}

@media screen and (max-width: 700px) {
    #header h1 {
        font-size: 40px;
    }
}

