@media screen and (max-width: 1200px) {

    #skillsSection h2 {
        font-size: 8vw;
    }
}

@media screen and (max-width: 900px) {
    #skillsSection {
        margin-bottom: -25px;
    }

    .skillfalse {
        width: 30vw;
        height: 30vw;
        margin: 6vw 6vw;
    }
    
    .skilltrue {
        width: 35vw;
        height: 35vw;
        margin: 3.5vw 3.5vw;
    }
    
    .skillfalse img {
        width: 22vw;
        padding: 4vw;
    }
    
    .skilltrue img {
        width: 27vw;
        padding: 2vw;
    }    
    .skilltrue h3 {
        font-size: 4vw;
        margin-top: -4vw;
    }
}

@media screen and (max-width: 700px) {
    #skillsSection h2 {
        font-size: 50px;
    }
}
