@media screen and (max-width: 1200px) {

    #portfolioSection h2 {
        font-size: 8vw;
    }
}

@media screen and (max-width: 900px) {
    #portfolioSection {
        margin-bottom: 0
    }

    #portfolioSection h2 {
        margin-top: 50px;
    }

    .portfolioItem {
       display: flex;
       flex-direction: column-reverse;
       margin: 20px auto;
    }

    #portfolioSection h3 {
        font-size: 40px;
    }
    
    #portfolioSection h4 {
        font-size: 20px;
    }
    
    #portfolioSection li {
        font-size: 16px;
    }

    .portfolioImgs {
        padding-top: 0;
    }
    
    .layoutImage {
        width: 70vw;
        padding: 0 10vw 0 0;
    }

    .websiteButton {
        height: 100px;
        width: 100px;
        border-radius: 50px;
        position: relative;
        left: 65vw;
        top: 100px;
        /* bottom: -10px; */
        font-size: 20px;
    }
}

@media screen and (max-width: 700px) {
    #portfolioSection h2 {
        font-size: 60px;
    }
}

@media screen and (max-width: 500px) {
    .websiteButton {
        left: 60vw;
        top: 50px;
    }
}

