@font-face {
  font-family: 'Consolas';
  src: url('../public/CONSOLA.TTF');
}

@font-face {
  font-family: 'Commissioner';
  src: url('../public/Commissioner-VariableFont_wght.ttf')
}

@font-face {
  font-family: 'Coiny';
  src: url('../public/Coiny-Regular.ttf');
}

body {
  margin: 0;
  font-family: "Commissioner";
  /* font-weight: 100; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #CCFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section-title {
  font-family: "Coiny" !important;
}

#sectionBorder1 {
  width: 100vw;
  height: 175px;
  position: relative;
  top: -135px;
  z-index: 10;
}

#sectionBorder2 {
  width: 100vw;
  height: 175px;
  position: relative;
  top: -50px;
  z-index: 10;
  transform: rotate(180deg);
}

#sectionBorder3 {
  width: 100vw;
  height: 175px;
  position: relative;
  z-index: 10;
}

#skills {
  position: relative;
  top: -275px;
} 

#portfolio, #contact {
  position: relative;
  top: -200px;
}

#references {
  color: #CCFFFF;
  height: 20px;
  background-color: #00002f;
  text-align: right;
  padding-bottom: 10px;
}

#references p {
  margin: 0 20px;
  font-size: 12px;
}

#references a {
  color: #CCFFFF !important;
}

@media screen and (max-width: 900px) {
  #sectionBorder1 {
    height: 100px;
    top: -125px;
  }
  
  #sectionBorder2 {
    height: 100px;
    top: 0;
  }
  
  #sectionBorder3 {
    height: 100px;
    top: -10px;
  }

  #skills {
    top: -250px;
  } 
  
  #portfolio, #contact {
    top: -100px;
  }
}





