#skillsSection {
    width: 100vw;
    background-color: #00002f;
    color: #CCFFFF;
    margin: 0;
    margin-top: -150px;
    margin-bottom: 25px;
    padding: 50px 0px 0px 0px;
    text-align: center;
    position: relative;
    /* top: -150px;  */
}

#skillsSection h2 {
    font-size: 4.5vw;
    width: 100vw;
    display: block;
    margin-top: 0;
}

#skillsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.skillfalse {
    background-color: #CCFFFF;
    color:rgb(3, 3, 31);
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    margin: 2vw 6vw;
    transition: all 0.2s;
    align-items: center;
}

.skilltrue {
    background-color: #94ebb8;
    color:rgb(3, 3, 31);
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    margin: -4vw 3.5vw;
    transition: all 0.2s;
    animation-name: skillBounce;
    animation-timing-function: ease-in-out;
    animation-duration: 0.75s;
}

.skillfalse img {
    width: 10vw;
    padding: 1.5vw;
    transition: all 0.2s;
}

.skillfalse h3 {
    font-family: "Consolas";
    display: none;
}

.skilltrue img {
    width: 12vw;
    padding: 1.5vw;
    transition: all 0.2s;
}

.skilltrue h3 {
    font-family: "Consolas";
    font-size: 2vw;
    margin-top: -2vw;
}

@keyframes skillBounce {
    0% { transform: scale(100%) }
    25% { transform: scale(105%) }
    50% { transform: scale(95%) }
    75% { transform: scale(100%) }
}
