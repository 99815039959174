#contactSection {
    width: 100vw;
    background-color: #00002f;;
    color: #CCFFFF;
    margin: 0;
    margin-top: -40px;
    padding: 75px 0px;
    position: relative;
}

#contactSection h2 {
    font-size: 4.5vw;
    width: 100vw;
    display: block;
    margin-top: 0;
    text-align: center;
}

.contactContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80vw;
    margin: 100px auto;
    align-items: center;
}

form {
    margin: 0 auto;
    width: max-content;
}

label {
    font-size: 30px;
    width: 150px;
    float: left;
    display: block;
}

input {
    font-size: 30px;
    border-radius: 5px;
    margin: 10px;
    width: 500px;
    font-family: 'Consolas';
}

textarea {
    font-size: 30px;
    border-radius: 5px;
    margin: 10px;
    width: 500px;
}

button {
    font-family: 'Consolas';
    font-size: 30px;
    color:rgb(3, 3, 31);
    background-color: #CCFFFF;
    border: none;
    width: 175px;
    height: 75px;
    border-radius: 62.5px;
    margin: 25px auto;
    display: block;
}

button:hover {
    background-color: #94ebb8;
    transition: all 0.25s;
    animation-name: skillBounce;
    animation-timing-function: ease-in-out;
    animation-duration: 0.75s;
}

.contactInfo {
    text-align: center;
    font-size: 30px;
}

#headshot {
    height: 400px;
    border-radius: 200px;
}

.contactInfo p {
    margin: 5px;
}

