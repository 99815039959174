canvas {
    position: relative;
    background-color: #CCFFFF;
    margin: 0;
}

#header-textbox {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
}

#header h1 {
    font-size: 4.5vw;
    color: #00002f;
    font-weight: 500;
    margin: 20px;
    line-height: 1;
}

#header .section-title {
    font-size: 6vw !important;
}
