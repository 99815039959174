.navBar {
    position: fixed;
    top: 0px;
    right: 0px;
    text-align: right;
    color: rgb(3, 3, 31);
    z-index: 15;
}

.navBar > img {
    height: 8vh;
    max-height: 50px;
    width: 8vh;
    max-width: 50px;
    padding: 4vh;
    position: relative;
    z-index: 15;
}

#navList {
    background-color: #94ebb8;
    border-radius: 50vh;
    height: 75vh;
    width: 75vh;
    position: absolute;
    right: -37.5vh;
    top: -37.5vh;
    z-index: 0;
    animation-name: bounce;
    animation-timing-function: ease-out;
    animation-duration: 0.75s;
}

.navBar ul {
    margin-top: 48vh;
}

.navBar li {
    font-size: 3.5vh;
    list-style-type: none;
    padding: 0.75vh 40vh 0.75vh 0;
}

.navBar li:hover {
    color: gray;
    transform: scale(110%);
    transition: all 0.5s;
    cursor: pointer;
}

@keyframes bounce {
    0% { transform: scale(0) }
    25% { transform: scale(110%) }
    50% { transform: scale(90%) }
    75% { transform: scale(100%) }
}

@keyframes wordBounce {
    0% { transform: scale(100%) }
    25% { transform: scale(105%) }
    50% { transform: scale(110%) }
    75% { transform: scale(105%) }
}

@media screen and (max-width: 700px) {
    .navBar > img {
        padding: 0.5vh;
    }
}